import Skeleton from 'react-loading-skeleton'

// Import the CSS for the skeleton loader
import 'react-loading-skeleton/dist/skeleton.css'
import styles from './AchieveSkeleton.module.scss'

const AchieveSkeleton = ({ variant, ...props }) => {
  return (
    <div className={styles['achieve-skeleton']} data-variant={variant}>
      <Skeleton {...props} />
    </div>
  )
}

export default AchieveSkeleton
export { AchieveSkeleton }
